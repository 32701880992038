import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { db } from '../firebaseConfig';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import './AnalysisHistory.css';
import '../App.css';

const AnalysisHistory = () => {
  const { currentUser } = useContext(AppContext);
  const [userAnalyses, setUserAnalyses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserAnalyses = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const uid = currentUser.uid;

        // Reference to the 'dialogues' subcollection under the current user's document
        const dialoguesRef = collection(db, 'users', uid, 'dialogues');

        // Create a query to order the dialogues by timestamp in descending order
        const q = query(dialoguesRef, orderBy('timestamp', 'desc'));

        // Execute the query to get the documents
        const querySnapshot = await getDocs(q);

        // Map over the documents to extract the data
        const analyses = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            dialogue: data.dialogue || 'No dialogue available.',
            result: data.result || 'No analysis result available.',
            timestamp: data.timestamp,
          };
        });

        setUserAnalyses(analyses);
      } catch (error) {
        console.error('Error fetching analyses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserAnalyses();
  }, [currentUser]);

  if (loading) {
    return (
      <div className="analysis-history futuristic-box">
        <p>Loading your analyses...</p>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className="analysis-history futuristic-box">
        <p>Please log in to view your analysis history.</p>
      </div>
    );
  }

  if (!userAnalyses || userAnalyses.length === 0) {
    return (
      <div className="analysis-history futuristic-box">
        <p>You have no past analyses.</p>
      </div>
    );
  }

  return (
    <div className="analysis-history futuristic-box">
      <h2>Your Analysis History</h2>
      <ul>
        {userAnalyses.map((analysis) => (
          <li key={analysis.id}>
            <div className="analysis-item">
              <div className="analysis-timestamp">
                {analysis.timestamp && analysis.timestamp.toDate
                  ? analysis.timestamp.toDate().toLocaleString()
                  : analysis.timestamp
                  ? new Date(analysis.timestamp).toLocaleString()
                  : 'Unknown Date'}
              </div>
              <details>
                <summary>View Analysis</summary>
                <div className="analysis-content">
                  <h3>Input Dialogue:</h3>
                  <p>{analysis.dialogue}</p>
                  <h3>Analysis Result:</h3>
                  <p>{analysis.result}</p>
                </div>
              </details>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AnalysisHistory;
