import React, { useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import './AnalysisHistoryPage.css';

const AnalysisHistoryPage = () => {
  const {
    currentUser,
    userAnalyses,
    isPremium,
    loggedInUser,
    signInWithGoogle,
    fetchUserAnalyses,
  } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (loggedInUser && currentUser && isPremium) {
      // Fetch user analyses
      fetchUserAnalyses(currentUser.uid);
    }
  }, [loggedInUser, currentUser, isPremium, fetchUserAnalyses]);

  if (!loggedInUser) {
    return (
      <div className="analysis-history-page">
        <p>Please login to view your analysis history.</p>
        <button onClick={signInWithGoogle} className="futuristic-button">
          Login with Google
        </button>
      </div>
    );
  }

  if (!isPremium) {
    return (
      <div className="analysis-history-page">
        <p>The analysis history feature is available for premium users only.</p>
        <button onClick={() => navigate('/premium')} className="futuristic-button">
          Go Premium
        </button>
      </div>
    );
  }

  return (
    <div className="analysis-history-page">
      <h1>Your Analysis History</h1>
      {userAnalyses.length > 0 ? (
        <div className="analyses-list">
          {userAnalyses.map((analysis) => (
            <div key={analysis.id} className="analysis-item">
              <div className="analysis-header">
                <span className="analysis-date">
                  {analysis.timestamp ? analysis.timestamp.toDate().toLocaleString() : ''}
                </span>
              </div>
              <div className="analysis-dialogue">
                <h3>Dialogue:</h3>
                <p>{analysis.dialogue}</p>
              </div>
              <div className="analysis-result">
                <h3>Result:</h3>
                <pre>{analysis.result}</pre>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No analyses found.</p>
      )}
    </div>
  );
};

export default AnalysisHistoryPage;
