import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqFtQiObrEeE_1NSF-ND6dYul7sjxAkvI",
  authDomain: "type-whisperer02.firebaseapp.com",
  projectId: "type-whisperer02",
  storageBucket: "type-whisperer02.appspot.com",
  messagingSenderId: "660675845100",
  appId: "1:660675845100:web:a5ce4fb6ad03fc39d6fec9",
  measurementId: "G-DVYGBMN72X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Auth
const db = getFirestore(app);
const auth = getAuth(app);

// Export the initialized instances
export { app, db, auth };
