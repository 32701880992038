// firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyBqFtQiObrEeE_1NSF-ND6dYul7sjxAkvI",
  authDomain: "type-whisperer02.firebaseapp.com",
  projectId: "type-whisperer02",
  storageBucket: "type-whisperer02.appspot.com",
  messagingSenderId: "660675845100",
  appId: "1:660675845100:web:a5ce4fb6ad03fc39d6fec9",
  measurementId: "G-DVYGBMN72X"
};

// Initialize Firebase (only if not already initialized)
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Auth and Firestore
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

// Export the instances to use elsewhere
export { firebaseApp, auth, db };
