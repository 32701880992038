import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import './footer.css';
const Footer = () => {
  const {
    userType,
    creditBalance,
    dialogueCount,
    handleGetCredits,
    handleCancelSubscription,
  } = useContext(AppContext);

  return (
    <footer className="futuristic-footer">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
   <img
        src="/images/footer.png"
        alt="Made with love by MBTI Enthusiasts"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
</div>

      <div className="credits-container">
        {userType === 'premium' ? (
          <>
            <span>Credits: {creditBalance}</span>
            <button
              onClick={handleCancelSubscription}
              className="futuristic-button"
            >
              Cancel Subscription
            </button>
          </>
        ) : (
          <>
            <span>Dialogues Remaining: {dialogueCount}</span>
            <button onClick={handleGetCredits} className="futuristic-button">
              Upgrade to Premium
            </button>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
