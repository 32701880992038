// Header.js

import React, { useContext, useState } from 'react';
import { AppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import ProfileWindow from './ProfileWindow'; // Import ProfileWindow component
import './Header.css';

const Header = () => {
  const { currentUser, logout, signInWithGoogle } = useContext(AppContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openProfile = () => {
    setShowProfile(true);
  };

  const closeProfile = () => {
    setShowProfile(false);
  };

  return (
    <header className="header">
      <div className="header-container">
        {/* Logo */}
        <div className="logo-container">
          <Link to="/">
            <img src="/images/TW0333.png" alt="Logo" className="logo" />
          </Link>
        </div>

        {/* Navigation */}
        <nav className={`navigation ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <Link to="/">Personality Analysis</Link>
            </li>
            <li>
              <Link to="/chatbot">Personal Development Coach</Link>
            </li>
            <li>
              <Link to="/dreamchatbot">Dream Interpreter</Link>
            </li>
          </ul>

          {/* Authentication Buttons */}
          <div className="auth-buttons">
            {currentUser ? (
              <>
                <button onClick={openProfile} className="profile-button">
                  Profile
                </button>
                <button onClick={logout} className="logout-button">
                  Logout
                </button>
              </>
            ) : (
              <button onClick={signInWithGoogle} className="login-button">
                Login with Google
              </button>
            )}
          </div>
        </nav>

        {/* Menu Icon for Mobile */}
        <div className="menu-icon" onClick={toggleMenu}>
          <div className={`menu-icon-line ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`menu-icon-line ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`menu-icon-line ${isMenuOpen ? 'open' : ''}`}></div>
        </div>
      </div>

      {showProfile && currentUser && <ProfileWindow onClose={closeProfile} />}
    </header>
  );
};

export default Header;
