import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import ContentSection from './ContentSection';
import CopyTemplateButton from './CopyTemplateButton'; // Import the CopyTemplateButton
import './HomePage.css';

const HomePage = () => {
  const {
    currentUser,
    userType,
    mbtiType,
    userDataLoaded,
  } = useContext(AppContext);

  console.log('HomePage currentUser:', currentUser);
  console.log('HomePage userType:', userType);
  console.log('HomePage mbtiType:', mbtiType);
  console.log('HomePage userDataLoaded:', userDataLoaded);

  return (
    <div className="homepage">
      <div className="welcome-section">
        <h1>Welcome to Type Whisperer</h1>
        {currentUser && mbtiType && (
        <p className="mbti-greeting" style={{ textAlign: 'center' }}>
  Hello, {currentUser.displayName}! Your MBTI type is set to {mbtiType}.
</p>
        )}
        {/* Include the CopyTemplateButton */}
        <CopyTemplateButton />
      </div>

      <ContentSection />

      <div className="about-section">
        <h2>About Type Whisperer</h2>
        <p>
        <strong>Type Whisperer</strong> is an advanced AI-powered tool that revolutionizes personality analysis by combining MBTI and Enneagram insights. Using cutting-edge AI technology, Type Whisperer offers a comprehensive MBTI AI personality mapping and analysis, providing users with accurate and detailed personality assessments. Our platform also features an AI-driven Enneagram and Tritype identification tool, allowing users to explore deeper layers of their personality through our AI Enneagram profiling and Tritype analyzer.
      </p>
      <p>
        With our advanced Enneagram AI analysis and Tritype identification AI, we empower users with actionable insights into their personality psychology. Type Whisperer is an innovative online MBTI test that integrates both MBTI and Enneagram AI for a holistic view, helping users compare and understand their personality from multiple frameworks. Our AI-driven personality development tools are designed to enhance self-awareness, making it an invaluable resource for personal growth.
      </p>
      <p>
        Whether you're exploring personality psychology for personal insight or professional use, Type Whisperer offers an unparalleled experience in AI-powered personality analysis. With AI-driven insights and online analysis tools, we bring the world of MBTI and Enneagram into the future, offering comprehensive, user-friendly, and data-driven personality tests.
      </p>
        <p>
          {userType === 'premium'
            ? 'As a premium member, you have access to advanced features and additional credits.'
            : 'Upgrade to premium to access advanced features and additional credits.'}
        </p>
      </div>
    </div>
  );
};

export default HomePage;
