import React from 'react';

const MAX_DIALOGUE_CHARACTERS = 6500;

const DialogueInput = ({ dialogue, handleDialogueChange }) => {
  return (
    <>
      <textarea
        id="dialogue"
        value={dialogue}
        onChange={handleDialogueChange}
        maxLength={MAX_DIALOGUE_CHARACTERS}
        placeholder="Write about yourself, enter emails, text messages, characters dialouge, resume / coverletters, anyones expression of language or enter our questionaire..."
      />
      <div className="character-count">
        {dialogue.length}/{MAX_DIALOGUE_CHARACTERS} characters
      </div>
    </>
  );
};

export default DialogueInput;
