import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import './ProfileWindow.css';

const ProfileWindow = ({ onClose }) => {
  const {
    currentUser,
    mbtiType,
    setMbtiType,
    userType,
    creditBalance,
    dialogueCount,
  } = useContext(AppContext);

  const updateMbtiType = (e) => {
    const selectedType = e.target.value;
    setMbtiType(selectedType);
  };

  return (
    <div className="profile-popup">
      <div className="profile-popup-window">
        <button className="close-profile-popup" onClick={onClose}>
          ×
        </button>
        <h2>Profile</h2>
        <p>Name: {currentUser.displayName}</p>
        <p>Email: {currentUser.email}</p>
        <p>MBTI Type:</p>
        <select id="mbtiType" value={mbtiType} onChange={updateMbtiType}>
          <option value="">Select your MBTI type</option>
          <option value="ENFJ">ENFJ</option>
          <option value="ENFP">ENFP</option>
          <option value="ENTJ">ENTJ</option>
          <option value="ENTP">ENTP</option>
          <option value="ESFJ">ESFJ</option>
          <option value="ESFP">ESFP</option>
          <option value="ESTJ">ESTJ</option>
          <option value="ESTP">ESTP</option>
          <option value="INFJ">INFJ</option>
          <option value="INFP">INFP</option>
          <option value="INTJ">INTJ</option>
          <option value="INTP">INTP</option>
          <option value="ISFJ">ISFJ</option>
          <option value="ISFP">ISFP</option>
          <option value="ISTJ">ISTJ</option>
          <option value="ISTP">ISTP</option>
        </select>
        <br />
        <p>
          Subscription: {userType.charAt(0).toUpperCase() + userType.slice(1)}
        </p>
        {userType === 'premium' ? (
          <p>Credits Balance: {creditBalance}</p>
        ) : (
          <p>Dialogues Remaining: {dialogueCount}</p>
        )}
      </div>
    </div>
  );
};

export default ProfileWindow;
