// ChatWithResults.jsx

import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../AppContext';
import { useLocation } from 'react-router-dom'; // Import useLocation
import {
  addDoc,
  collection,
  onSnapshot,
  serverTimestamp,
  query,
  where,
  orderBy,
} from 'firebase/firestore';

import { db } from '../firebase'; // Ensure this import points to your Firebase configuration

import './chatbot.css'; // Chat-specific styles
import MBTIPopup from './MBTIPopup';

const ChatWithResults = () => {
  const {
    currentUser,
    userType,
    mbtiType,
    signInWithGoogle,
    setMbtiType,
    handleGetCredits,
    userDataLoaded,
  } = useContext(AppContext);

  const location = useLocation();
  const initialMessage = (location.state && location.state.initialMessage) || '';

  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMBTIPopup, setShowMBTIPopup] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const chatContainerRef = useRef(null); // Ref for auto-scrolling

  // Initialize sessionId and handle inactivity
  useEffect(() => {
    const storedLastActivity = localStorage.getItem('lastActivity');
    const storedSessionId = localStorage.getItem('sessionId');
    const now = Date.now();

    if (!storedLastActivity || now - parseInt(storedLastActivity) > 60 * 60 * 1000) {
      // More than an hour has passed, or no lastActivity
      const newSessionId = now.toString();
      localStorage.setItem('sessionId', newSessionId);
      localStorage.setItem('lastActivity', now.toString());
      setSessionId(newSessionId);
    } else {
      // Less than an hour has passed
      localStorage.setItem('lastActivity', now.toString());
      setSessionId(storedSessionId);
    }
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (userDataLoaded && sessionId) {
      if (currentUser && (userType === 'premium' || userType === 'standard')) {
        if (mbtiType) {
          unsubscribe = initializeConversation();
        } else {
          setShowMBTIPopup(true);
        }
      } else {
        // User is not authenticated or doesn't have access
        setConversation([]);
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser, userType, mbtiType, userDataLoaded, sessionId]);

  useEffect(() => {
    // If there's an initial message, send it as the system prompt
    if (initialMessage && currentUser && sessionId) {
      sendInitialMessage(initialMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMessage, currentUser, sessionId]);

  useEffect(() => {
    // Auto-scroll to the latest message
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [conversation]);

  const initializeConversation = () => {
    if (!currentUser) {
      console.error('No current user available');
      return;
    }
    console.log('Current user UID:', currentUser.uid);

    try {
      // Reference to the user's chat_with_results subcollection
      const chatWithResultsCollection = collection(
        db,
        'users',
        currentUser.uid,
        'chat_with_results'
      );

      // Query messages ordered by timestamp and filtered by sessionId
      const q = query(
        chatWithResultsCollection,
        where('sessionId', '==', sessionId),
        orderBy('timestamp', 'asc')
      );

      // Set up a Firestore listener for the conversation
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const messages = [];
          snapshot.docChanges().forEach((change) => {
            const docData = change.doc.data();
            const messageId = change.doc.id;

            if (change.type === 'added' || change.type === 'modified') {
              // User message
              if (docData.prompt && !docData.response) {
                // Exclude initial message from display
                if (!docData.isInitialMessage) {
                  messages.push({
                    sender: 'user',
                    text: docData.prompt,
                    timestamp: docData.timestamp
                      ? docData.timestamp.toMillis()
                      : Date.now(),
                    docId: messageId,
                  });
                }
              }
              // Assistant response
              if (docData.response) {
                messages.push({
                  sender: 'assistant',
                  text: docData.response,
                  timestamp: docData.responseTimestamp
                    ? docData.responseTimestamp.toMillis()
                    : Date.now(),
                  docId: messageId,
                });
              }
            }
          });

          // Update conversation state
          setConversation((prevMessages) => {
            const allMessages = [...prevMessages, ...messages];

            // Remove duplicates
            const uniqueMessages = [];
            const messageKeys = new Set();
            allMessages.forEach((msg) => {
              const key = msg.docId + msg.sender;
              if (!messageKeys.has(key)) {
                messageKeys.add(key);
                uniqueMessages.push(msg);
              }
            });

            // Sort messages by timestamp
            uniqueMessages.sort((a, b) => a.timestamp - b.timestamp);

            return uniqueMessages;
          });
        },
        (error) => {
          console.error('Error fetching conversation:', error);
          if (error.code === 'permission-denied') {
            alert(
              'You do not have permission to read messages. Please check your Firestore security rules.'
            );
          }
        }
      );

      return unsubscribe;
    } catch (error) {
      console.error('Error initializing conversation:', error);
    }
  };

  const sendInitialMessage = async (initialMessage) => {
    if (!initialMessage.trim()) return;
    if (!currentUser) {
      alert('Please login to use this feature.');
      return;
    }
    console.log('Sending initial message as user UID:', currentUser.uid);

    try {
      // Reference to the user's chat_with_results subcollection
      const chatWithResultsCollection = collection(
        db,
        'users',
        currentUser.uid,
        'chat_with_results'
      );

      // Add a new document with the user's message
      await addDoc(chatWithResultsCollection, {
        prompt: initialMessage.trim(),
        mbtiType: mbtiType || 'Unknown',
        timestamp: serverTimestamp(),
        sessionId: sessionId,
        isInitialMessage: true, // Mark this message as the initial message
      });

      // Update lastActivity in localStorage
      localStorage.setItem('lastActivity', Date.now().toString());
    } catch (error) {
      console.error('Error sending initial message:', error);
      if (error.code === 'permission-denied') {
        alert(
          'You do not have permission to send messages. Please check your Firestore security rules.'
        );
      } else {
        alert('An error occurred while sending the initial message.');
      }
    }
  };

  const sendMessage = async () => {
    if (!message.trim()) return;
    if (!currentUser) {
      alert('Please login to use this feature.');
      return;
    }
    console.log('Sending message as user UID:', currentUser.uid);

    setLoading(true);

    try {
      // Reference to the user's chat_with_results subcollection
      const chatWithResultsCollection = collection(
        db,
        'users',
        currentUser.uid,
        'chat_with_results'
      );

      // Add a new document with the user's message
      await addDoc(chatWithResultsCollection, {
        prompt: message.trim(),
        mbtiType: mbtiType || 'Unknown',
        timestamp: serverTimestamp(),
        sessionId: sessionId,
      });

      // Update lastActivity in localStorage
      localStorage.setItem('lastActivity', Date.now().toString());

      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      if (error.code === 'permission-denied') {
        alert(
          'You do not have permission to send messages. Please check your Firestore security rules.'
        );
      } else {
        alert('An error occurred while sending the message.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMBTISelection = (selectedMBTI) => {
    setMbtiType(selectedMBTI);
    setShowMBTIPopup(false);
  };

  const clearConversation = () => {
    // Generate a new sessionId
    const newSessionId = Date.now().toString();
    localStorage.setItem('sessionId', newSessionId);
    localStorage.setItem('lastActivity', Date.now().toString());
    setSessionId(newSessionId);
    // Clear the conversation state
    setConversation([]);
  };

  if (!userDataLoaded) {
    return (
      <div className="chatbot-page">
        <div className="glass-container">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className="chatbot-page">
        <div className="glass-container">
          <p>Please login to use this feature.</p>
          <button onClick={signInWithGoogle} className="send-button">
            Login with Google
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="chatbot-page">
      {showMBTIPopup && <MBTIPopup onSelectMBTI={handleMBTISelection} />}

      {!showMBTIPopup && (
        <div className="glass-container">
          <h1>Chat with your Type Whisperer results</h1>

          {/* Broom button to clear the conversation */}
          <button onClick={clearConversation} className="broom-button">
            🧹
          </button>

          <div className="chat-container" ref={chatContainerRef}>
            {conversation.map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${
                  msg.sender === 'user' ? 'user' : 'assistant'
                }`}
              >
                <div className="message">{msg.text}</div>
              </div>
            ))}
            {loading && (
              <div className="chat-message assistant">
                <div className="message">Typing...</div>
              </div>
            )}
          </div>

          <div className="message-input">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              disabled={loading}
            />
            <button
              onClick={sendMessage}
              className="send-button"
              disabled={loading}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWithResults;
