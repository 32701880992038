import React, { useState } from 'react';
import './MBTIPopup.css';

const MBTIPopup = ({ onSelectMBTI }) => {
  const [selectedMBTI, setSelectedMBTI] = useState('');

  const mbtiTypes = [
    'ENFJ', 'ENFP', 'ENTJ', 'ENTP',
    'ESFJ', 'ESFP', 'ESTJ', 'ESTP',
    'INFJ', 'INFP', 'INTJ', 'INTP',
    'ISFJ', 'ISFP', 'ISTJ', 'ISTP'
  ];

  const handleSubmit = () => {
    if (selectedMBTI) {
      onSelectMBTI(selectedMBTI);
    } else {
      alert('Please select your MBTI type.');
    }
  };

  return (
    <div className="mbti-popup-overlay">
      <div className="mbti-popup">
        <h2>Select Your MBTI Type</h2>
        <select
          value={selectedMBTI}
          onChange={(e) => setSelectedMBTI(e.target.value)}
        >
          <option value="">Select MBTI Type</option>
          {mbtiTypes.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
        <button onClick={handleSubmit} className="futuristic-button">
          Save
        </button>
      </div>
    </div>
  );
};

export default MBTIPopup;
