import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import DialogueInput from './DialogueInput';
import AnalysisResult from './AnalysisResult';
import LoadingSpinner from './LoadingSpinner';
import AnalysisHistory from './AnalysisHistory';

const ContentSection = () => {
    const {
        currentUser,
        userType,
        dialogueCount,
        creditBalance,
        loading,
        analyzeDialogue,
        handleDialogueChange,
        dialogue,
        result,
        usePremiumService,
        handleTogglePremiumUsage,
    } = useContext(AppContext);

    return currentUser ? (
        <>
            <section className="analysis-section">
                <div className="credits-info">
                    {userType === 'premium' && usePremiumService ? (
                        <h2>Remaining Credits: {creditBalance}</h2>
                    ) : (
                        <h2>Remaining Free Tier Credits: {dialogueCount}</h2>
                    )}
                </div>

                <div className="input-container futuristic-box">
                    <h2>Text to analyze:</h2>
                    <DialogueInput
                        dialogue={dialogue}
                        handleDialogueChange={handleDialogueChange}
                    />
                    <button
                        onClick={analyzeDialogue}
                        className="futuristic-button"
                        disabled={
                            loading ||
                            (userType === 'premium' && usePremiumService && creditBalance <= 0) ||
                            ((userType === 'standard' || !usePremiumService) && dialogueCount <= 0)
                        }
                    >
                        Analyze
                    </button>

                    {userType === 'premium' && (
                        <div className="switch-container">
                            <span>Use Premium Features</span>
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={usePremiumService}
                                    onChange={handleTogglePremiumUsage}
                                />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    )}

                    {/* Loading Spinner Container */}
                    {loading && (
                        <div className="loading-spinner-container">
                            <LoadingSpinner />
                        </div>
                    )}
                </div>

                <AnalysisResult result={result} />
            </section>

            {userType === 'premium' && <AnalysisHistory />}
        </>
    ) : (
        <div className="login-message futuristic-box">
            Please login for free to use Type Whisperer.
        </div>
    );
};

export default ContentSection;
