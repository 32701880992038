// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AppProvider } from './AppContext';
import HomePage from './components/HomePage';
import ChatbotPage from './components/ChatbotPage';
import AnalysisHistoryPage from './components/AnalysisHistoryPage';
import Header from './components/Header';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import DreamChatbotPage from './components/DreamChatbotPage';
import ChatWithResults from './components/ChatWithResults';
import ChatWithSelf from './components/ChatWithSelf'; // Import the new component
import './App.css';

function App() {

  // Custom component to handle routes that need to pass state to ChatWithResults
  const ChatWithResultsWrapper = () => {
    const location = useLocation();
    const initialMessage = (location.state && location.state.initialMessage) || '';
    return <ChatWithResults initialMessage={initialMessage} />;
  };

  return (
    <AppProvider>
      <Router>
        <div className="app">
          <Header />
          <div className="content">
            <ErrorBoundary>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/chatbot" element={<ChatbotPage />} />
                <Route path="/analysis-history" element={<AnalysisHistoryPage />} />
                <Route path="/dreamchatbot" element={<DreamChatbotPage />} />
                <Route path="/chatwithresults" element={<ChatWithResultsWrapper />} />
                <Route path="/chatwithself" element={<ChatWithSelf />} /> {/* New Route */}
                {/* Add other routes as needed */}
              </Routes>
            </ErrorBoundary>
          </div>
          <Footer />
        </div>
      </Router>
    </AppProvider>
  );

}

export default App;
