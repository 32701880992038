import React, { useState } from 'react';

const CopyTemplateButton = () => {
  const textToCopy = `
1. When faced with a new situation, how do you typically react?

a) I jump right in and adapt as I go.
b) I observe carefully before taking action.
c) I analyze the situation logically before deciding.
d) I consider how it aligns with my values and feelings.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

2. In social settings, which statement best describes you?

a) I thrive in large groups and enjoy meeting new people.
b) I prefer intimate gatherings with close friends.
c) I enjoy socializing but need time alone to recharge afterward.
d) I participate when necessary but generally keep to myself.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

3. How do you prefer to receive information?

a) Through concrete facts and practical details.
b) By interpreting meanings and seeing possibilities.
c) Via hands-on experience and real-world application.
d) Through theoretical concepts and abstract ideas.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

4. When making decisions, you tend to focus on:

a) Objective criteria and logical analysis.
b) Personal values and how others will be affected.
c) The most efficient and effective outcome.
d) Maintaining harmony and avoiding conflict.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

5. Which best describes your approach to planning?

a) I like to have a detailed plan and stick to it.
b) I prefer to be spontaneous and keep my options open.
c) I set goals but remain flexible in how to achieve them.
d) I focus on the present moment and take things as they come.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

6. In group projects, what role do you often assume?

a) Leader who organizes and delegates tasks.
b) Creative thinker who generates ideas.
c) Supporter who helps others and ensures cohesion.
d) Analyst who evaluates options and potential pitfalls.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

7. What motivates you the most?

a) Achieving success and recognition.
b) Building deep relationships and connections.
c) Understanding complex systems and ideas.
d) Feeling secure and prepared for the future.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

8. How do you handle conflict?

a) Confront it directly to resolve the issue.
b) Seek a compromise to satisfy all parties.
c) Avoid it and hope it resolves itself.
d) Analyze the causes before addressing it.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

9. Which statement resonates with you the most?

a) I am driven to help others and meet their needs.
b) I strive for personal success and achievement.
c) I seek knowledge and understanding above all.
d) I value peace and avoid disruption.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

10. When working on a task, do you prefer:

a) Starting immediately and figuring it out along the way.
b) Researching and planning thoroughly before beginning.
c) Collaborating with others for input and feedback.
d) Working independently to maintain focus.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

11. Your friends describe you as:

a) Energetic and outgoing.
b) Thoughtful and introspective.
c) Reliable and organized.
d) Flexible and easy-going.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

12. How do you respond to change?

a) I embrace it as an opportunity for growth.
b) I feel uneasy but adapt over time.
c) I resist it and prefer consistency.
d) I analyze the benefits and drawbacks before accepting it.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

13. What is most important to you in life?

a) Freedom to explore and experience new things.
b) Building a secure and stable foundation.
c) Making a meaningful impact on others.
d) Achieving personal excellence and mastery.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

14. When making purchases, you tend to:

a) Impulsively buy what catches your eye.
b) Research extensively before deciding.
c) Stick to essentials and avoid extravagance.
d) Consider how it aligns with your identity and values.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

15. In conversations, you are more likely to:

a) Listen more than you speak.
b) Dominate the discussion with enthusiasm.
c) Share ideas when prompted but prefer not to overshare.
d) Engage deeply, asking probing questions.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):

16. Which scenario appeals most to you?

a) Exploring a new city without a set itinerary.
b) Attending a workshop to learn a new skill.
c) Hosting a gathering to strengthen relationships.
d) Completing a challenging project successfully.

Your Answer (enter the letter(s) you chose):
Elaboration (optional):


`;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      alert('Template copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <button
      id="copy-button"
      type="button"
      onClick={handleCopyClick}
      className="futuristic-button"
    >
      Copy Questionaire
    </button>
  );
};

export default CopyTemplateButton;
