import React, { createContext, useState, useEffect } from 'react';
import { auth } from './firebaseConfig';
import {
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from 'firebase/auth';
import {
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  updateDoc,
  increment,
} from 'firebase/firestore';
import { db } from './firebaseConfig'; // Ensure this is correctly imported

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userType, setUserType] = useState(''); // 'standard' or 'premium'
  const [mbtiType, setMbtiTypeState] = useState('');
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [creditBalance, setCreditBalance] = useState(0); // For premium users
  const [dialogueCount, setDialogueCount] = useState(5); // Default dialogues for standard users
  const [dialogue, setDialogue] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [usePremiumService, setUsePremiumService] = useState(false);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log('Sign-in successful:', result.user);
        // The onAuthStateChanged listener will handle the rest
      })
      .catch((error) => {
        console.error('Error during sign-in:', error);
      });
  };

  const logout = async () => {
    try {
      await auth.signOut();
      setCurrentUser(null);
      setUserType('');
      setMbtiTypeState('');
      setUserDataLoaded(false);
      setCreditBalance(0);
      setDialogueCount(5);
      setDialogue('');
      setResult('');
      setLoading(false);
      setUsePremiumService(false);
      console.log('User signed out successfully.');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      console.log('Auth State Changed:', user);
      if (user) {
        setCurrentUser(user);

        const userDocRef = doc(db, 'users', user.uid);

        const unsubscribeUser = onSnapshot(userDocRef, async (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            setUserType(data.userType || 'standard');
            setMbtiTypeState(data.mbtiType || '');
            setCreditBalance(data.creditBalance || 0);
            setDialogueCount(
              data.dialogueCount !== undefined ? data.dialogueCount : 5
            );
            setUserDataLoaded(true);
          } else {
            await setDoc(userDocRef, {
              userType: 'standard',
              mbtiType: '',
              creditBalance: 0,
              dialogueCount: 5,
            });
            setUserType('standard');
            setMbtiTypeState('');
            setCreditBalance(0);
            setDialogueCount(5);
            setUserDataLoaded(true);
          }
        });

        return () => {
          unsubscribeUser();
        };
      } else {
        setCurrentUser(null);
        setUserType('');
        setMbtiTypeState('');
        setCreditBalance(0);
        setDialogueCount(5);
        setUserDataLoaded(false);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const updateMbtiType = async (newMbtiType) => {
    if (currentUser) {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        await setDoc(userDocRef, { mbtiType: newMbtiType }, { merge: true });
        setMbtiTypeState(newMbtiType);
      } catch (error) {
        console.error('Error updating MBTI type:', error);
      }
    }
  };

  const checkUserType = async () => {
    if (currentUser) {
      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserType(data.userType || 'standard');
        } else {
          setUserType('standard');
        }
      } catch (error) {
        console.error('Error checking user type:', error);
      }
    }
  };

  const handleDialogueChange = (e) => {
    setDialogue(e.target.value);
  };

  const analyzeDialogue = async () => {
    if (!dialogue.trim()) {
      alert('Please enter some dialogue.');
      return;
    }

    if (userType === 'standard' && dialogueCount <= 0) {
      alert('You have no remaining dialogues. Please upgrade to premium.');
      return;
    }

    if (userType === 'premium' && usePremiumService && creditBalance <= 0) {
      alert('You have no remaining credits. Please purchase more credits.');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('https://typewhisperer.com/api/analyze', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dialogue }),
      });

      const data = await response.json();

      if (response.ok) {
        setResult(data.analysis);

        // Update the dialogue count or credits
        const userDocRef = doc(db, 'users', currentUser.uid);

        if (userType === 'premium' && usePremiumService) {
          await updateDoc(userDocRef, { creditBalance: increment(-1) });
          setCreditBalance((prev) => prev - 1);
        } else if (userType === 'standard' || !usePremiumService) {
          await updateDoc(userDocRef, { dialogueCount: increment(-1) });
          setDialogueCount((prev) => prev - 1);
        }
      } else {
        console.error('Analysis error:', data.error);
        alert('An error occurred during analysis.');
      }
    } catch (error) {
      console.error('Request error:', error);
      alert('An error occurred during analysis.');
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePremiumUsage = () => {
    setUsePremiumService(!usePremiumService);
  };

  const handleGetCredits = async () => {
    if (!currentUser) {
      alert('You must be logged in to purchase credits.');
      return;
    }

    try {
      const response = await fetch(
        'https://typewhisperer.com/api/create-checkout-session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: currentUser.uid }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        window.location.href = data.url;
      } else {
        alert('An error occurred while starting the checkout process.');
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
      alert('An error occurred while starting the checkout process.');
    }
  };

  const handleCancelSubscription = async () => {
    if (!currentUser) {
      alert('You must be logged in to cancel your subscription.');
      return;
    }

    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const docSnap = await getDoc(userDocRef);

      if (!docSnap.exists()) {
        alert('No active subscription found.');
        return
      }

      const userData = docSnap.data();
      if (userData.userType !== 'premium' || !userData.subId) {
        alert('No active subscription found.');
        return;
      }
      const response = await fetch(
        'https://typewhisperer.com/api/cancel-subscription',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userData.subId }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        alert('Subscription successfully cancelled.');
        setUserType('standard');
        await updateDoc(userDocRef, {
          userType: 'standard',
          subId: null,
        });
      } else {
        alert('An error occurred while cancelling your subscription.');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      alert('An error occurred while cancelling your subscription.');
    }
  };

  return (
    <AppContext.Provider
      value={{
        currentUser,
        userType,
        mbtiType,
        userDataLoaded,
        signInWithGoogle,
        logout,
        setMbtiType: updateMbtiType,
        checkUserType,
        creditBalance,
        dialogueCount,
        dialogue,
        result,
        loading,
        analyzeDialogue,
        handleDialogueChange,
        handleGetCredits,
        handleCancelSubscription,
        usePremiumService,
        handleTogglePremiumUsage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
